.heroes {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding-bottom: 140px;

  @include from-sm {
    padding-bottom: 160px;
  }

  @include from-xl {
    padding-bottom: 0;
  }

  &__slider {
    width: 100%;
    overflow: visible;
    position: relative;
    padding: 0 30px;

    @include from-sm {
      padding: 0;;
      margin: 0 auto;
      max-width: 546px;
    }

    @include from-md {
      max-width: 738px;
    }

    @include from-lg {
      max-width: 930px;
    }

    @include from-xxxl {
      max-width: 1200px;
    }

    &--grid {
      padding: 0;

      @include from-sm {
        overflow: hidden;
      }
    }
  }

  &__wrapper {
    padding: 60px 0;

    @include from-xxxl {
      padding: 70px 0;
    }

    &--grid {
      flex-wrap: wrap;
      justify-content: center;
      gap: 45px 15px;
      padding: 30px 0;

      @include before-sm {
        width: 100% !important;
        transform: none !important;
      }

      @include from-xxs {
        gap: 45px 30px;
      }

      @include from-xs {
        gap: 60px 30px;
      }

      @include from-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
        gap: 25px 0;
      }

      @include from-xxxl {
        padding: 40px 0;
      }
    }
  }

  &__slide {
    @include from-sm {
      max-width: 300px;
    }

    @include from-xxxl {
      max-width: 365px;
    }

    &--grid {
      max-width: 135px;

      @include before-sm {
        margin: 0 !important;
      }

      @include from-xs {
        max-width: 162px;
      }

      @include from-sm {
        max-width: 100%;
      }
    }
  }

  &__scrollbar {
    height: 60px;
    //background: rgba(255, 255, 255, 0.4);
    position: relative;
    margin: 30px 50px 0 50px;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 100%;
      background: rgba(255, 255, 255, 0.4);
      height: 1px;
      top: 30px;
      position: relative;
    }

    @include before-sm {
      display: none;
    }

    @include from-xxxl {
      margin-top: 50px;
    }

    &--grid {
      margin-top: 40px;

      @include from-xxxl {
        margin-top: 50px;
      }
    }

    &-drag {
      height: 3px;
      border-radius: 1px;
      background: $white;
      position: absolute;
      left: 0;
      z-index: 2;
      top: 29px;
    }
  }

  &__arrow {
    background: none;
    border: none;
    padding: 0;
    width: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    transition: opacity 0.15s;
    cursor: pointer;

    @include from-sm {
      display: none;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
      
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      opacity: 0;
    }

    svg {
      display: block;
      width: 100%;
    }
  }

  &__empty {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    max-width: 350px;
    margin: auto;

    @include from-md {
      font-size: 32px;
      max-width: 460px;
    }

    @include from-xl {
      font-size: 46px;
      max-width: 650px;
    }
  }
}

.heroes-card {
  position: relative;
  display: block;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }

  @include before-sm {
    max-width: 300px;
    margin: 0 auto;
  }

  &--grid {
    @include before-sm {
      max-width: 100%;
      margin: 0;
    }
  }

  &__frame {
    display: block;
    width: 100%;
  }

  &__text {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    transform: translate(0, 100%);
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1;
    z-index: 3;

    @include from-xxs {
      bottom: 35px;
    }

    @include from-xxxl {
      bottom: 40px;
    }

    &--grid {
      bottom: 15px;

      @include from-xs {
        bottom: 20px;
      }

      @include from-xxxl {
        bottom: 25px;
      }
    }
  }

  &__name {
    font-size: 30px;
    letter-spacing: 0.05em;
    white-space: nowrap;

    @include from-xxs {
      font-size: 38px;
    }

    @include from-xxxl {
      font-size: 45px;
    }

    &--grid {
      font-size: 16px;

      @include from-xs {
        font-size: 20px;
      }

      @include from-xxxl {
        font-size: 25px;
      }
    }
  }

  &__surname {
    font-size: 22px;
    letter-spacing: 0.1em;

    @include from-xxs {
      font-size: 25px;
    }

    @include from-xxxl {
      font-size: 30px;
    }

    &--grid {
      font-size: 10px; 

      @include from-xs {
        font-size: 14px;
      }

      @include from-xxxl {
        font-size: 16px;
      }
    }
  }

  &__person,
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    mask-image: url('#{$img-path}/heroes/frame-mask.svg');
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: 50% 99.5%;
  }

  &__person--grid,
  &--grid::before {
    bottom: 2px;

    @include from-xs {
      bottom: 3px;
    }
  }

  &::before {
    content: '';
    top: 80%;
    background-image: linear-gradient(0, rgba(0, 0, 0, 0.4) 0%, transparent);
    z-index: 2;
  }

  &__person {
    top: -10%;  

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 100%;
    }
  }
}
