@mixin from-xxs {
  @media all and (min-width: #{$xxs}) {
    @content;
  }
}

@mixin before-xxs {
  @media all and (max-width: #{$xxs - 1}) {
    @content;
  }
}

@mixin from-xs {
  @media all and (min-width: #{$xs}) {
    @content;
  }
}

@mixin before-xs {
  @media all and (max-width: #{$xs - 1}) {
    @content;
  }
}

@mixin from-sm {
  @media all and (min-width: #{$sm}) {
    @content;
  }
}

@mixin before-sm {
  @media all and (max-width: #{$sm - 1}) {
    @content;
  }
}

@mixin from-md {
  @media all and (min-width: #{$md}) {
    @content;
  }
}

@mixin before-md {
  @media all and (max-width: #{$md - 1}) {
    @content;
  }
}

@mixin from-lg {
  @media all and (min-width: #{$lg}) {
    @content;
  }
}

@mixin before-lg {
  @media all and (max-width: #{$lg - 1}) {
    @content;
  }
}

@mixin from-xl {
  @media all and (min-width: #{$xl}) {
    @content;
  }
}

@mixin before-xl {
  @media all and (max-width: #{$xl - 1}) {
    @content;
  }
}

@mixin from-xxl {
  @media all and (min-width: #{$xxl}) {
    @content;
  }
}

@mixin before-xxl {
  @media all and (max-width: #{$xxl - 1}) {
    @content;
  }
}

@mixin from-xxxl {
  @media all and (min-width: #{$xxxl}) {
    @content;
  }
}

@mixin before-xxxl {
  @media all and (max-width: #{$xxxl - 1}) {
    @content;
  }
}
