.popup-overlay {
  background-color: rgba(14, 20, 36, 0.9);
}

.popup-content {
  background-color: transparent;
  width: 100%;
  max-width: 1000px;
}

.popup-close {
  position: absolute;
  top: -40px;
  right: 7px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include from-xxs {
    right: 10px;
  }
  @include from-xs {
    right: 10px;
  }
  @include from-lg {
    top: -50px;
    right: 0;
    width: 31px;
    height: 31px;
  }

  & svg {
    width: 100%;
  }
}
