*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
	background: $white;
  font-family: $font-primary;
  font-style: normal;
  color: $white;
} 

.page {
  background: radial-gradient(#009EE1, #014493);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  @include from-xxxl {
    padding-top: 25px;
  }

  &--404 {
    background: radial-gradient(#D6D6D6, #969696);
  }

  &--hero {
    @include from-lg {
      min-height: 0;
      height: 100vh;
    }
  }

  &__inner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 0 15px 20px 15px;
    overflow-x: hidden;
    overflow-y: visible;

    &--hero { 
      @include from-lg {
        padding-bottom: 0;
        overflow: hidden;
      }
    }
  }

  &::before,
  &::after {
    content: '';
  }

  &::before,
  &__sparks {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  } 

  &::before {
    background: no-repeat top 100px left 50% / 100% auto url('#{$img-path}/heroes/bg-logo.svg');
    opacity: 0.07;
  }

  &--gold {
    background: linear-gradient(320deg, #BF8A35 0%, #C3903B 5.00%, #D0A34C 12.00%, #E5C268 21.00%, #EFD175 24.00%, #EBCB6F 26.00%, #DCB354 34.00%, #D7AB4B 39.00%, #D7AE4D 52.00%, #E4C366 57.00%, #F2DB82 62.00%, #FBE993 67.00%, #FFEF99 71.00%, #F1D983 76.00%, #D6AE59 86.00%, #C5933E 95.00%, #BF8A35 100%);
  
    &::before {
      opacity: 0.1;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::after {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      pointer-events: none;
      background: linear-gradient(180deg, rgba(61, 36, 7, 0.4) 0%, transparent 100%);
    }
  }

  &__sparks {
    background: no-repeat top 50% left 50% / 200% auto url('#{$img-path}/heroes/sparks.png');
    mix-blend-mode: screen;

    @include from-md {
      background-size: 150% auto;
    }

    @include from-xl {
      background-size: 100% auto;
    }
  }

  &__background {
    @include before-lg {
      display: none;
    }

    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 1080px;
    background: linear-gradient(to left, #307ed9, transparent);
    pointer-events: none;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right 0 top 0;
    }
  }

  &,
  &::before,
  &__sparks {
    background-attachment: fixed;
  }
}

.nowrap {
  white-space: nowrap;
}
