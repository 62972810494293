.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  margin-top: 30px;
  @include from-md {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__wrap {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 96px;
    @include from-sm {
      height: 100px;
    }
    @include from-lg {
      height: 90px;
    }
    @include from-xxl {
      height: 104px;
    }

    &:hover .gallery__img {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &__swiper {
    max-width: 900px;
    max-height: 500px;
    width: 86%;
    height: 150px;
    @include from-xxs {
      height: 180px;
    }
    @include from-xs {
      height: 210px;
    }
    @include from-sm {
      height: 275px;
    }
    @include from-md {
      width: 90%;
      height: 383px;
    }
    @include from-lg {
      width: 100%;
      height: auto;
    }

    & .swiper-slide {
      display: flex;
    }
  }

  &__swiper-wrap {
    position: relative;
  }

  &__arrow {
    background: none;
    border: none;
    padding: 0;
    width: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    transition: opacity 0.15s;
    cursor: pointer;
    @include from-lg {
      width: 25px;
    }

    &--prev {
      left: 2%;
      @include from-lg {
        left: 0;
      }
    }

    &--next {
      right: 2%;
      @include from-lg {
        right: 0;
      }


      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      opacity: 0;
    }

    svg {
      display: block;
      width: 100%;
    }
  }
}
