.header {
	position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 25px 0;

  @include from-xxxl {
    position: static;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s;
    background: linear-gradient(180deg, #002958 0%, transparent 100%);

    @include from-xxxl {
      display: none;
    }
  }

  &--fixed::before {
    opacity: 1;
  }

  &--gold::before {
    background: linear-gradient(180deg, #745217 0%, transparent 100%);
  }
 
  &__inner {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    @include from-xxs {
      padding: 0 30px;
    }
  }

  &__logo {
    width: 60px;
    flex-shrink: 0;
    margin: 0 13px 0 0;

    svg {
      width: 100%;
      display: block;
    }

    @include from-md {
      width: 90px;
      margin-right: 20px;
    }

    @include from-xl {
      width: 112px;
      margin-right: 25px;
    }
  } 

  &__name {
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0 15px 0 0;

    @include from-md {
      font-size: 20px;
    }

    @include from-xl {
      font-size: 27px;
    }
  }

  &__nav {
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;

    &-link {
      text-decoration: none;
      position: relative;

      &::before {
        @include from-sm {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          transition: 0.25s;
          opacity: 0;
          transform: translate(0, -2px);
          background: $white;
        }
      }
    
      &:hover {
        &::before {
          @include from-sm {
            opacity: 1;
            transform: translate(0, 0);
          }
        }
      }
    }

    &-text {
      @include before-sm {
        display: none;
      }

      font-size: 14px;
      font-weight: 500;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      white-space: nowrap;
    }

    &-icon {
      display: block;
      width: 25px;

      @include from-sm {
        display: none;
      }
    }
  }

  &__views {
    display: flex;
    gap: 10px;
    margin: 0 0 0 10px;

    @include from-xxs {
      margin-left: 20px;
      gap: 20px;
    }

    @include from-lg {
      margin-left: 30px;
    }

    @include from-xxl {
      margin-left: 50px;
    }
  }

  &__view {
    background: none;
    padding: 0;
    border: none;
    width: 34px;
    cursor: pointer;
    opacity: 0.3;
    transition: 0.25s opacity;

    &.active,
    &:hover {
      opacity: 1;
    }

    &[disabled] {
      pointer-events: none;
    }

    svg {
      display: block;
      width: 100%;
    }
  }

  &__filter {
    position: fixed;
    left: 15px;
    right: 15px;
    bottom: 20px;

    @include from-xs {
      width: 385px;
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
    }

    @include from-xl {
      position: static;
      width: auto;
      transform: none;
      margin: 0 0 0 30px;
    }

    @include from-xxl {
      margin-left: 50px;
    }
  }

  &__back {
    margin: 0 0 0 auto;
    padding: 8px 12px;
    color: $white;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #71B0D8;
    transition: background 0.25s;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }

    @include from-xxs {
      padding: 10px 20px;
    }

    @include from-md {
      padding: 13px 25px;
      font-size: 13px;
    }

    @include from-xl {
      font-size: 14px;
      padding: 22px 30px;
    }

    svg {
      width: 5px;
    }
  }
}

.filter {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  padding: 20px 10px;
  border: 1px solid #71B0D8;

  &--gold {
    border-color: $white;

    @include before-xl {
      background: rgba(69, 7, 7, 0.3);
      border-color: transparent;
    }
  }

  @include from-xl {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px 30px;
  }

  @include from-xxl {
    gap: 20px;
  }

  &__label {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0 0 20px 0;

    @include from-xl {
      margin: 0;
      text-align: left;
    }
  }

  &__stars {
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    @include from-xl {
      margin: 0;
    }
  }

  &__star {
    cursor: pointer;
    opacity: 0.3;
    transition: opacity 0.15s, transform 0.15s;
    padding: 0 3px;

    @include from-xxs {
      padding: 0 5px;
    }

    @include from-xxl {
      padding: 0 10px;
    }

    svg {
      display: block;
      width: 20px;
    }
    
    &.active {
      transform: scale(1.1);

      &,
      & ~ .filter__star {
        opacity: 1;
      } 

      & ~ .filter__star {
        transform: none;
      }
    }

    @include from-xl {
      &:hover {
        transform: scale(1.1);

        &,
        & ~ .filter__star {
          opacity: 1;
        } 

        & ~ .filter__star {
          transform: none;
        }
      }
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  &__range {
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent 0%, $white 15%, $white 85%, transparent 100%);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    max-width: 248px;
    margin: 0 auto;

    @include from-xxs {
      max-width: 285px;
    }

    @include from-xl {
      display: none;
    }
  }

  &__range::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #A8BFEE;
    border: 1px solid #C2D3F3;
  }

  &__range--gold::-webkit-slider-thumb {
    background: #c29f75;
    border-color: $white;
  }
}
