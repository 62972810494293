.not-found {
  margin: auto;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;

  &__code {
    font-size: 150px;

    @include from-md {
      font-size: 232px;
    }
  }

  &__message {
    font-size: 22px;
    letter-spacing: 0.05em;

    @include from-md {
      font-size: 32px;
    }
  }
}
