.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 190px;
  z-index: 2;

  @include from-xl {
    width: 220px;
  }

  &__svg {
    display: block;
    width: 100%;
  }

  &__path {
    animation: preloader-dash 3s ease-in-out infinite, preloader-color 3s ease-in-out infinite;
  }
}

@keyframes preloader-dash {
  0% {
    stroke-dasharray: 0, 50;
    stroke-dashoffset: 0;
  } 
  100% {
    stroke-dasharray: 200, 0;
    stroke-dashoffset: -70px;
  }
}

@keyframes preloader-color {
  0% {
    stroke: #c09965;
  }
  75% {
    stroke: #c09965;
  }
  100% {
    stroke: #fff;
  }
}
