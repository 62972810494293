///////////////////////////////////
// BREAKPOINTS
///////////////////////////////////
$xxxl: 1600px;
$xxl: 1400px;
$xl:  1200px;
$lg:  992px;
$md:  768px;
$sm:  576px;
$xs:  414px;
$xxs: 360px;

///////////////////////////////////
// COLORS
///////////////////////////////////
$black: #000000;
$white: #ffffff;
$blue: #0576BE;

///////////////////////////////////
// PATH
///////////////////////////////////
$img-path: "../../img";
$font-path: "../../font";

$font-primary: 'Futura PT', sans-serif;
