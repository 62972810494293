.hero {
  padding: 0 15px;

  @include from-lg {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }

  @include from-xs {
    padding: 0 30px;
  }

  &__inner {
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;

    @include from-lg {
      flex: 1 1 auto;
      display: flex;
      padding-bottom: 20px;
      overflow: hidden;
      position: relative;
    }

    @include from-xxxl {
      max-width: 1320px;
    }
  }

  &__person ,
  &__info {
    position: relative;
    z-index: 2;
  }

  &__info {
    max-width: 565px;
    margin: 0 auto;

    @include from-lg {
      width: 50%;
      max-width: 100%;
      margin: 75px 0 0 auto;
      overflow: auto;
      padding: 0 10px 0 25px;
    }

    @include from-xxxl {
      padding-left: 40px;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 5px;
    }
  }

  &__person {
    margin: 0 auto 22px auto;
    max-width: 375px;

    @include from-lg {
      max-width: 100%;
      margin: 0;
      position: absolute;
      left: 0;
      top: 0;
      bottom: -20px;
      width: 60%;
      overflow: hidden;
    }

    &-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 200px;
      background: linear-gradient(0, rgba(0, 105, 179, 1) 0%, transparent 100%);
      mask-size: cover;
      mask-repeat: no-repeat;
      mask-position: center bottom;

      @include from-lg {
        display: none;
      }
    }

    &-text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @include from-lg {
        display: none;
      }
    }

    img {
      display: block;
      width: 100%;

      @include from-lg {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        min-height: 800px;
        object-fit: contain;
        object-position: 0% 0%;
      }

      @include from-xxxl {
        min-height: 1000px;
      }
    }
  }

  &__name {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 0 0 12px 0;

    @include from-xxs {
      font-size: 42px;
    }

    @include from-xs {
      font-size: 46px;
    }

    @include from-xxxl {
      font-size: 64px;
      margin-bottom: 15px;
    }
  }

  &__position,
  &__birth {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    opacity: 0.6;

    @include from-lg {
      font-size: 16px;
    }
  }

  &__position {
    @include from-lg {
      margin: 0 0 20px 0;
    }

    @include from-xxxl {
      margin-bottom: 30px;
    }
  }

  &__birth {
    margin: 0 0 15px 0;

    @include from-lg {
      margin-bottom: 10px;
    }
  }

  &__name,
  &__position {
    &--desktop {
      @include before-lg {
        display: none;
      }
    }
  }

  &__awards {
    list-style: none;
    margin: 0 0 20px 0;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid #71B0D8;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    gap: 25px 14px;

    @include from-xxs {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: stretch;
    }

    @include from-xxxl {
      padding: 40px 30px;
      margin-bottom: 30px;
      border-radius: 20px;
    }

    &-item {
      width: 100%;
      text-align: center;
      max-width: 160px;

      @include from-xxs {
        width: calc(50% - 7px);
      }

      @include from-sm {
        width: calc(33.333% - 10px);
      }

      &--cup {
        display: flex;
        text-align: left;
        gap: 12px;
      }
    }

    &-text {
      align-self: center;
    }

    &-icon {
      display: block;
      width: 50px;
      height: 40px;
      margin: 0 auto 8px auto;

      @include from-xxs {
        height: 52px;
        padding: 5px 0;
      }

      @include from-xs {
        height: 58px;
        padding: 8px 0;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-name {
      font-size: 11px;
      text-transform: uppercase;
    }

    &-date {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      opacity: 0.7;
      margin: 3px 0 0 0;
    }

    &-cup {
      display: block;
      width: 30px;
      flex-shrink: 0;
      height: 80px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-number {
      font-size: 54px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
      color: transparent;
      text-stroke: 1px $white;
      -webkit-text-stroke: 1px $white;
      opacity: 0.7;

      @include from-xxs {
        font-size: 60px;
      }

      @include from-xs {
        font-size: 66px;
      }
    }
  }

  &__text {
    font-size: 16px;

    @include from-xxxl {
      font-size: 18px;
    }

    p,
    ul,
    ol {
      margin: 15px 0;

      @include from-xxxl {
        margin: 20px 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btn {
    position: fixed;
    top: 50%;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  &__prev {
    left: 1%;
    @include from-xxl {
      left: 5%;
    }
    @include from-xxxl {
      left: 7%;
    }
  }

  &__next {
     right: 1%;
    transform: rotate(180deg);
    @include from-xxl {
      right: 5%;
    }
    @include from-xxxl {
      right: 7%;
    }
  }
}
