.about {
  text-align: center;
  position: relative;
  z-index: 2;
  margin: auto;
  line-height: 1.2;

  &__title {
    max-width: 1230px;
    margin: 0 0 20px 0;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    
    @include from-md {
      font-size: 32px;
    }
  }

  &__text {
    max-width: 920px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 500;
    
    @include from-md {
      font-size: 21px;
    }
  }
}
